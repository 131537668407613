/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "[youtube=http://www.youtube.com/watch?v=oACt9R9z37U]"), "\n", React.createElement(_components.p, null, "Ya era hora de que alguien mejorara el EyeToy de Playstation, este adelanto viene por parte de Microsoft, la respuesta al revolucionario mando de la Wii es simplemente no usar mando, a parte del reconocimiento visual, también tiene este proyecto reconocimiento de voz. El video publicitario lo pinta muy bien, pero a mi se me ocurren varios problemas a un sistema como este; por ejemplo, igual que la Wii en algunos juegos necesitas mucho espacio; en juegos de coches como el que sale en el anuncio es muy incómodo llevar un mando imaginario, no lo veo lógico; la altura de la tele, cuando estas de pie la tele queda mas baja pero cuando estas sentado queda a la altura normal, se necesita una tele grande para jugar con esto. De todas formas no tengo muchas expectativas en este proyecto, seguramente podrían conseguir más de lo que harán."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
